<template>
    <v-card class="elevation-2 mx-auto mt-4 mb-4" max-width="80%"><!-- :sort-desc="true" -->
        <v-container style="max-width: 1000px; margin: auto;">
            <!-- Formulario de filtros, se muestra solo si el switch está activo -->
            <v-form v-show="showFilters" ref="filterForm" @submit.prevent="search"
                style="background-color: #e0f7fa; padding: 16px; border-radius: 8px;">
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-row>
                            <v-col>
                                <v-menu v-model="start_date_menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="period.start_date" label="Start Date" readonly
                                            v-bind="attrs" v-on="on" clearable></v-text-field>
                                    </template>
                                    <v-date-picker v-model="period.start_date" color="green lighten-1"
                                        :max="period.end_date" @change="start_date_menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <!-- <v-row v-if="period.start_date != null">
                            <v-col>
                                <v-menu v-model="end_date_menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="period.end_date" label="End Date" readonly v-bind="attrs"
                                            v-on="on" clearable></v-text-field>
                                    </template>
                                    <v-date-picker v-model="period.end_date" color="green lighten-1"
                                        :min="period.start_date" @change="end_date_menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row> -->
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                        <v-combobox v-model="filters.reason" :items="reasons" label="Reason" return-object multiple
                            clearable></v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- Search Button -->
                    <v-col cols="12" sm="12" md="12" class="d-flex justify-space-between">
                        <div>
                            <v-btn @click="searchFilters" class="mr-4" style="background-color: #1565C0; color: white;">
                                Search
                            </v-btn>
                            <v-btn @click="resetFilters" color="grey lighten-1" class="mr-4">
                                Reset Filters
                            </v-btn>
                            <!-- <v-btn @click="test" color="grey lighten-1" class="mr-4">
                                test
                            </v-btn> -->
                        </div>
                        <div>
                            <v-btn @click="exportReport" class="ml-4" style="background-color: #28a745; color: white;">
                                Export to Excel
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
        <!-- Contenedor para la imagen-botón -->
        <v-container v-if="!closedDay" class="d-flex justify-center py-4">
            <!-- Fila de imágenes -->
            <v-row justify="space-around" class="py-4">
                <!-- Clock In/Clock Out -->
                <v-col v-if="!wc && !lunch && !adminTask" class="d-flex flex-column align-center text-center">
                    <v-img
                        :src="clockIn ? require('@/assets/Clock/clock-in.png') : require('@/assets/Clock/clock-out.png')"
                        contain max-height="100" max-width="100" class="cursor-pointer mb-2" @click="toggleClock"
                        :style="{ color: clockIn ? 'rgba(0, 128, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)' }" />
                    <div class="font-weight-bold">
                        {{ clockIn ? 'CLOCK IN' : 'CLOCK OUT' }}
                    </div>
                </v-col>

                <!-- Lunch In/Lunch Out -->
                <v-col v-if="clock && !wc && !adminTask" class="d-flex flex-column align-center text-center">
                    <v-img
                        :src="lunchIn ? require('@/assets/Lunch/lunch-in.png') : require('@/assets/Lunch/lunch-out.png')"
                        contain max-height="100" max-width="100" class="cursor-pointer mb-2" @click="toggleLunch"
                        :style="{ color: lunchIn ? 'rgba(0, 128, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)' }" />
                    <div class="font-weight-bold">
                        {{ lunchIn ? 'GO TO LUNCH' : 'BACK LUNCH' }}
                    </div>
                </v-col>

                <!-- WC In/WC Out -->
                <v-col v-if="clock && !lunch && !adminTask" class="d-flex flex-column align-center text-center">
                    <v-img :src="wcIn ? require('@/assets/WC/wc-in.png') : require('@/assets/WC/wc-out.png')" contain
                        max-height="100" max-width="100" class="cursor-pointer mb-2" @click="toggleWC"
                        :style="{ color: wcIn ? 'rgba(0, 128, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)' }" />
                    <div class="font-weight-bold">
                        {{ wcIn ? 'BIO BREAK' : 'BACK BIO BREAK' }}
                    </div>
                </v-col>

                <!-- Admin Task In/Out -->
                <v-col v-if="clock && !lunch && !wc" class="d-flex flex-column align-center text-center">
                    <v-img
                        :src="adminTaskIn ? require('@/assets/Reasons/admin_task_in.png') : require('@/assets/Reasons/admin_task_out.png')"
                        contain max-height="100" max-width="100" class="cursor-pointer mb-2" @click="adminTaskFunction"
                        :style="{ color: adminTaskIn ? 'rgba(0, 128, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)' }" />
                    <div class="font-weight-bold">
                        {{ adminTaskIn ? 'ADMIN TASK IN' : 'ADMIN TASK OUT' }}
                    </div>
                </v-col>

                <!-- Imágenes sin lógica adicional pero con evento de clic -->
                <v-col v-if="(clock && !lunch && !wc && !adminTask && department !== 'Marketing Department') ||
                    (clock && !lunch && !wc && department === 'Calendar Department')"
                    v-for="(image, index) in additionalImages" :key="index"
                    class="d-flex flex-column align-center text-center"><!-- (!loading && clock && !lunch && !wc && !adminTask) || (!loading && clock && !lunch && !wc && department === 'Calendar Department') -->
                    <v-img :src="require(`@/assets/Reasons/${image.fileName}.png`)" contain max-height="100"
                        max-width="100" class="cursor-pointer mb-2" @click="handleClick(image.label)" />
                    <div class="font-weight-bold">
                        {{ image.label }}
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-data-table :headers="dynamicHeaders" :items="filteredActivities" sort-by="time" :sort-desc="true"
            class="elevation-1" hide-default-footer :items-per-page="-1" :item-class="getRowClass">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="font-weight-bold">Ext: {{ extension }}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-switch v-model="showFilters" label="Filters"></v-switch>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        class="mr-3 mt-5"></v-text-field>
                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshActivities">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <div v-if="department !== 'Marketing Department'" class="d-flex flex-column align-start mt-2"
                    style="margin-left: 16px;">
                    <span class="font-weight-bold text-body-1 text-success" style="color: green;">
                        Inbound Calls: {{ callsSummary?.Inbound ?? 'N/A' }}
                    </span>
                    <span class="font-weight-bold text-body-1" style="color: red;">
                        Outbound Calls: {{ callsSummary?.Outbound ?? 'N/A' }}
                    </span>
                </div>
            </template>
            <template v-slot:[`item.type_of_call.link`]="{ item }">
                <v-icon v-if="item.type_of_call?.link && item.type_of_call.link !== ''" color="primary"
                    @click="redirectToLink(item.type_of_call?.link)" style="cursor: pointer;">
                    mdi-link
                </v-icon>
            </template>

            <template v-slot:[`item.notes`]="{ item }">
                <div class="wrap-column">
                    {{ item.notes }}
                </div>
            </template>

            <!-- Sale Column -->
            <template v-slot:[`item.sale`]="{ item }">
                {{ item.sale ? `$${item.sale.toFixed(2)}` : '-' }}
            </template>

            <!-- Slot personalizado para Time -->
            <template v-slot:item.time="{ item }">
                <strong>{{ item.time }}</strong>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="450">
            <v-card>
                <v-card-title class="headline"></v-card-title>
                <v-card-text>
                    <h2>Are you sure you want to clock out and close the day?</h2>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="confirmClockOut">Yes</v-btn>
                    <v-btn color="red darken-1" text @click="dialog = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Diálogo -->
        <v-dialog v-model="formDialog" max-width="500px" :persistent="type_of_action === 'WALK IN'">
            <v-card>
                <v-card-title class="text-h5">{{ this.type_of_action }}</v-card-title>

                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
                        <!-- Campo para number -->
                        <v-text-field v-if="type_of_action != 'ADMIN TASK' && department != 'Calendar Department'"
                            v-model="formData.number" label="Number" :rules="[fillCondition, validateNumberField]"
                            required /><!-- type="number" :rules="[rules.required, rules.isNumber]" -->

                        <!-- Campo para reason -->
                        <v-combobox v-if="type_of_action != 'ADMIN TASK'" v-model="formData.reason"
                            :items="filteredReasonOptions" label="Reason" :rules="[reasonCondition]" required
                            clearable></v-combobox>


                        <v-text-field
                            v-if="formData.reason === 'Instructor`s Sale' || formData.reason === 'Road Test Instructor Support' || formData.reason === 'Instructor Support'"
                            v-model="formData.instructor" label="Instructor" :rules="[fillCondition]"
                            required></v-text-field>

                        <!-- Campo para sale -->
                        <v-text-field v-if="type_of_action != 'ADMIN TASK'" v-model="formData.sale" label="Sale"
                            type="number" :rules="[saleCondition]" required />

                        <!-- Campo para link -->
                        <v-text-field v-if="type_of_action != 'ADMIN TASK'" v-model="formData.link" label="Link"
                            type="url"></v-text-field>

                        <v-text-field v-if="type_of_action === 'ADMIN TASK'" v-model="formData.reason" label="Reason"
                            @input="convertToUppercase" @keyup.enter.prevent="toggleAdminTask" :rules="[linkCondition]"
                            required />

                        <v-textarea v-if="department != 'Calendar Department'" v-model="formData.notes" label="Notes"
                            rows="4" placeholder="Enter additional notes here" />
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-btn v-if="type_of_action != 'ADMIN TASK'" color="green darken-1" text
                        @click="submitForm">SAVE</v-btn>
                    <v-btn v-if="type_of_action === 'ADMIN TASK'" color="green darken-1" text
                        @click="toggleAdminTask">IN</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog content-class="dialog-height" v-model="walkPreview" max-width="900">
            <WalkInsForm :itemPreview="itemPreview" :name_staff="name_staff" :walkIndex="walkIndex" :walkItem="walkItem"
                :key="walkPreview.toString()" @sent-info="handleInfoFromChild" />
        </v-dialog>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </v-card>
</template>

<script>
import { get_employee_number } from '../../utils/get_employee_number.js'
import { GET_WALK_INS_FORMS_BY_EMPLOYEE } from '../../utils/endpoints.js';
import { bus } from '../../main.js';
import WalkInsForm from '../../components/Walk_ins/WalkInsForm.vue';
import { DateTime } from 'luxon';
import { exportWalkInsToExcel, filterWalkIns, getEmployeesWithWalkIns, getLocations, deleteWalkInByUUID, fetchTodayWalkIns, fetchTodayWalkIns_ByEmployee, } from '../../utils/WalkIns/walk_ins_CRUD.js'
import { fetchTodayActivities, createActivity, addTodayActivity, fetchActivitySummary, exportActivitiesToExcel, fetchActivitiesWithReasonEmployee, getCallsSummary } from '../../utils/Sales/activity_report_CRUD.js'
import { getEmployeeByNumber } from '../../utils/employees_CRUD.js'

export default {


    components: {
        WalkInsForm
    },
    data: () => ({
        //loading: true,
        department: '',
        callsSummary: null,
        reasons: [
            "Info/Sold other",
            "Info/Sold mine",
            "Info/Not Sold",
            "Outbound FU",
            "Personal FU",
            "Schedule",
            "Complaint",
            "Other",
            "Outbound MC",
            /* "CHAT",
            "Walk In",
            "EMAIL-SMS", */
        ],
        employeeData: {},
        type_of_job: '',
        // Walk ins
        itemPreview: -1,
        name_staff: '',
        walkIndex: -1,
        walkItem: null,
        walkPreview: false,
        staffSelect: null,


        type_of_action: '',
        reasonOptions: [
            'Info/Not Sold',
            'Info/Sold mine',
            'Info/Sold other',
            'Schedule',
            'Complaint',
            'Other',
            'Outbound FU',
            'Outbound MC',
            'Personal FU'
        ],
        formDialog: false,
        valid: false,
        formData: {
            number: null,
            reason: "",
            sale: null,
            link: "",
            notes: "",
            instructor: "",
        },
        closedDay: false,
        dialog: false,
        clock: null,
        wc: null,
        adminTask: null,
        lunch: null,
        extension: '',
        clockIn: true,
        lunchIn: true,
        wcIn: true,
        adminTaskIn: true,
        activities: [],
        // Filters
        period: {
            end_date: null,
            start_date: null,
            end_time: null,
            start_time: null,
        },
        start_date_menu: false,
        end_date_menu: false,
        start_time_menu: false,
        end_time_menu: false,
        // Filters
        users: [],
        locations: null,
        showFilters: false,
        datePickers: {
            creationDateMenu: false,
        },
        filters: {
            creationTime: null,
            creationDate: null,
            location: null,
            staff_name: null,
            reason: null,
        },
        locationSelected: null,
        employeeSelected: null,
        filteredWalkins: null,
        /////////////////
        showLoader: false,
        walkIndex: -1,
        walkItem: null,
        walkPreview: false,
        employee_number: '',
        walk_ins_forms: [],
        search: '',
        headers: [
            { text: 'Time', value: 'time' },
            { text: 'Number', value: 'number' },
            { text: 'Reason', value: 'reason' },
            { text: 'Instructor', value: 'instructor' },
            { text: 'Type of Activity', value: 'type_of_call.type' },
            { text: 'Sale', value: 'sale' },
            { text: 'Notes', value: 'notes' },
            { text: 'Link', value: 'type_of_call.link' },
        ],
        editedItem: {
            uuid: '',
            staff_name: '',
            employee_number: '',
            date_time: null
        },
        additionalImages: [
            { fileName: 'inbound', label: 'INBOUND' },
            { fileName: 'outbound', label: 'OUTBOUND' },
            { fileName: 'chat', label: 'CHAT' },
            { fileName: 'walk-ins', label: 'WALK IN' },
            { fileName: 'message', label: 'EMAIL-SMS' },
        ]
    }),
    computed: {
        dynamicHeaders() {
            // Verifica si existe al menos un instructor válido
            const hasInstructor = this.filteredActivities.some(activity =>
                activity.instructor && activity.instructor.trim() !== '' // Verifica que no sea nulo, indefinido o cadena vacía
            );

            // Filtra los encabezados dinámicamente
            return this.headers.filter(
                header => hasInstructor || header.value !== 'instructor' // Incluye la columna instructor solo si es necesario
            );
        },
        fillCondition() {
            // Retorna una regla basada en type_of_action
            return (value) => {
                if (this.type_of_action === 'WALK IN' || this.type_of_action === 'CHAT') {
                    return true; // Si es "CHAT", la regla siempre pasa
                }
                return !!value || 'Required';
            };
        },
        validateNumberField() {
            // Validación específica para números, paréntesis, '+' y espacios
            return (value) => {
                if (!value) {
                    return true; // Permite valores vacíos
                }
                const pattern = /^[0-9()+\s-]*$/; // Patrón permitido
                return pattern.test(value) || "Only numbers, parentheses, '+', spaces, and '-' are allowed";
            };
        },
        filteredReasonOptions() {
            // Verificar si el departamento es "Calendar Department"
            const isCalendarDepartment = this.department === "Calendar Department";

            // Verificar si el tipo de acción es "INBOUND" o "OUTBOUND"
            const isInboundOrOutbound = ["INBOUND", "OUTBOUND"].includes(this.type_of_action);

            // Verificar si el tipo de acción es "CHAT"
            const isChat = this.type_of_action === "CHAT";

            // Verificar si el tipo de acción es "EMAIL-SMS"
            const isEmailSms = this.type_of_action === "EMAIL-SMS";

            // Verificar si el tipo de acción es "WALK IN"
            const isWalkIn = this.type_of_action === "WALK IN";


            // Filtrar opciones según las condiciones
            if (isCalendarDepartment && isInboundOrOutbound) {
                return [
                    "Last minute Cancellation",
                    "Rescheduling",
                    "Follow-up",
                    "Instructor`s Sale", // Asumiendo que tienes una propiedad instructorName
                    "Road Test Instructor Support", // Asumiendo que tienes una propiedad instructorName
                    "Instructor Support"
                ];
            }

            if (isCalendarDepartment && isChat) {
                return [
                    "Instructors Chats",
                    "Instructors Chats + Google Chats",
                    "Same Day Chat",
                    "Road Test & Appointments",
                    "8x8",
                    "8x8 + Instructor`s Chats",
                    "Meet Assistance"
                ];
            }

            if (isCalendarDepartment && isEmailSms) {
                return [
                    "Expired Credits",
                    "New Online Registration",
                    "Web BTW",
                    "Road Test Support",
                    "Student Schedule",
                    "Form Submission",
                    "Department Collaboration Request"
                ];
            }

            if (isWalkIn) {
                return [
                    "Info/Sold mine",
                    "Info/Sold other"
                ];
            }

            // Si el tipo de acción es "WALK IN", "EMAIL-SMS" o "CHAT", retorna las opciones específicas
            if (["EMAIL-SMS", "CHAT"].includes(this.type_of_action) && this.department != "Calendar Department") {
                return ["Info/Sold mine", "Info/Sold other"];
            }

            // Si no se cumplen las condiciones especiales, retorna todas las opciones
            return this.reasons;
            /* if (["WALK IN", "EMAIL-SMS", "CHAT"].includes(this.type_of_action)) {
                return ["Info/Sold mine", "Info/Sold other"];
            }
            return this.reasons; // Retorna todas las opciones si no es ninguno de los casos especiales */
        },
        reasonCondition() {
            // Retorna una regla basada en type_of_action
            return (value) => {
                if ((this.type_of_action === 'WALK IN') || ((this.type_of_action === 'CHAT' || this.type_of_action === 'EMAIL-SMS') && this.department != "Calendar Department")) {
                    return true;
                }
                return !!value || 'Required';//|| this.type_of_action === 'WALK IN' && this.department != "Calendar Department"
            };
        },
        saleCondition() {
            // Retorna una regla basada en type_of_action
            return (value) => {
                if ((this.type_of_action === 'WALK IN' || this.type_of_action === 'EMAIL-SMS' || this.type_of_action === 'CHAT' || this.type_of_action === 'INBOUND' || this.type_of_action === 'OUTBOUND') && (this.formData.reason === 'Info/Sold other' || this.formData.reason === 'Info/Sold mine')) {
                    return !!value || 'Required';
                }
                return true;
            };
        },
        linkCondition() {
            // Retorna una regla basada en type_of_action
            return (value) => {
                if (this.type_of_action === 'WALK IN' || this.type_of_action === 'EMAIL-SMS' || this.type_of_action === 'CHAT') {
                    return true; // Si es "CHAT", la regla siempre pasa
                }
                return !!value || 'Required';
            };
        },

        filteredActivities() {
            if (
                Array.isArray(this.activities) && // Verifica que sea un array
                this.activities.length > 0 && // Asegura que no esté vacío
                this.activities[0].activities // Verifica que `activities` exista en el primer elemento
            ) {
                return this.activities[0].activities.filter(activity =>
                    activity.reason.toLowerCase().includes(this.search.toLowerCase())
                );
            } else {
                return [];
            }
        },
    },
    watch: {
        formDialog(val) {
            if (val) {
                this.resetForm();
            }
        },
        'period.start_time'(val) {
            console.log("start_time: ", val);
            if (val != null) {
                //this.period.end_time = '18:00';
            } else {
                this.period.start_time = null;
                this.period.end_time = null;
            }
        },
        'period.start_date'(val) {
            console.log("start_date: ", val);
            if (val != null) {
                //this.period.end_date = val;
            } else {
                this.period.end_date = null;
                this.period.start_date = null;
            }
        },
        locationSelected(newLocations) {
            if (newLocations && newLocations.length) {
                // Mapea las ubicaciones seleccionadas a solo los nombres de las ubicaciones
                this.filters.location = newLocations.map(item => item.location);
            } else {
                // Si no hay ninguna ubicación seleccionada, asegúrate de limpiar el filtro
                this.filters.location = [];
            }
        },
        async showFilters(val) {
            console.log("showFilters/val: ", val);
            if (!val) {
                this.resetFilters();
            } else {
                this.showLoader = true;
                //this.users = await fetchActiveEmployees();
                //this.locations = await getLocations();
                //console.log("users: ", this.users);
                //console.log("this.locations: ", this.locations);
                this.showLoader = false;
            }
        },
        walkPreview(val) {
            console.log("walkPreview: ", val);
            if (!val) {
                this.walkItem = null;
                this.walkIndex = -1;
                this.itemPreview = -1;
            }
        }
    },
    async mounted() {
        try {
            this.showLoader = true;
            console.log("-------------------MOUNTED.......................")
            bus.$emit('login-success');
            bus.$emit('permissions');
            /* this.employee_number = get_employee_number();
            console.log("employee_number: ", this.employee_number);
    
            this.staffSelect = await getEmployeeByNumber(this.employee_number);//this.get_EmployeeData()
            console.log("this.staffSelect: ", this.staffSelect);
            this.name_staff = `${this.staffSelect.name} ${this.staffSelect.last_name}`;
            console.log("this.name_staff: ", this.name_staff);
            this.department = this.staffSelect.department;
            console.log("this.department: ", this.department);
            this.employeeData = {
                employee_number: this.employee_number,
                name: this.staffSelect.name,
                last_name: this.staffSelect.last_name,
                department: this.staffSelect.department
            }; */
            //this.loading = true;
            await this.getEmployeeData();


            let { extension_phone_8x8, type_of_job } = await getEmployeeByNumber(this.employee_number);
            console.log("extension_phone_8x8: ", extension_phone_8x8);
            this.extension = extension_phone_8x8;
            console.log("this.extension: ", this.extension);
            this.type_of_job = type_of_job;
            console.log("this.type_of_job: ", this.type_of_job);

            //this.activities = await fetchTodayActivities(this.employee_number);
            console.log("<<<<<<<<<<<< this.employeeData: ", this.employeeData);
            this.activities = await fetchTodayActivities(this.employeeData);
            console.log("this.activities", this.activities);
            if (this.activities.length > 0) {
                console.log("this.activities: ", this.activities);
                console.log("this.activities: ", this.activities[0].activities);
                this.clock = this.activities[0].actions.clock;
                this.wc = this.activities[0].actions.wc;
                this.lunch = this.activities[0].actions.lunch;
                this.adminTask = this.activities[0].actions.adminTask;
                console.log("<<<<<<<< this.clock: ", this.clock);
                console.log("this.wc: ", this.wc);
                console.log("this.lunch: ", this.lunch);
                this.clockIn = !this.clock;
                this.lunchIn = !this.lunch;
                this.wcIn = !this.wc;
                this.adminTaskIn = !this.adminTask;
                this.closedDay = this.activities[0].status;
                let body = {
                    employeeData: this.employeeData,
                };
                this.callsSummary = await getCallsSummary(body);
                console.log("this.callsSummary: ", this.callsSummary);
                //this.loading = false;
            } else {
                //this.loading = false;
            }
            //console.log("------- this.loading: ", this.loading);
            this.showLoader = false;
        } catch (error) {
            console.error("Error Mounted!");
            this.showLoader = false;
            alert("Error Mounted!");
        }
    },
    methods: {
        async getEmployeeData() {
            try {
                this.employee_number = get_employee_number();
                console.log("employee_number: ", this.employee_number);
                this.staffSelect = await getEmployeeByNumber(this.employee_number);//this.get_EmployeeData()
                console.log("this.staffSelect: ", this.staffSelect);
                this.name_staff = `${this.staffSelect.name} ${this.staffSelect.last_name}`;
                console.log("this.name_staff: ", this.name_staff);
                this.department = this.staffSelect.department;
                console.log("this.department: ", this.department);
                this.employeeData = {
                    employee_number: this.employee_number,
                    name: this.staffSelect.name,
                    last_name: this.staffSelect.last_name,
                    department: this.staffSelect.department
                };
            } catch (error) {
                alert("Error getting Employee Data");
            }
        },
        async exportReport() {
            try {
                this.showLoader = true;
                console.log("this.filters: ", this.filters);
                console.log("this.activities: ", this.activities);
                //await exportActivitiesToExcel(this.activities);
                await exportActivitiesToExcel(this.filters);
                this.showLoader = false;
            } catch (error) {
                this.showLoader = false;
                console.error("exportReport/Error: ", error)
            }

        },
        convertToUppercase() {
            this.formData.reason = this.formData.reason.toUpperCase(); // Convierte a mayúsculas
        },
        adminTaskFunction() {
            if (!this.adminTask) {
                this.handleClick('ADMIN TASK')
            } else {
                this.toggleAdminTask();
            }
        },
        openWalkIn() {
            console.log("Opening Form Dialog");
            this.walkPreview = true;
        },
        async handleInfoFromChild(info) {
            console.log("**** info: ", info);
            this.walkPreview = info; //Close dialog

            this.formDialog = true;


            //this.activities = await fetchTodayActivities(this.employeeData);
            //console.log("<<<<<<<<<<<<<< this.activities: ", this.activities);
            //this.walk_ins_forms = await this.get_WalkIns_Forms();
            //console.log("this.walk_ins_forms: ", this.walk_ins_forms);
            this.walkItem = null;
            this.walkIndex = -1;
        },
        resetForm() {
            this.formData = {
                number: null,
                reason: "",
                sale: null,
                link: "",
            };
            this.valid = false; // Restablece la validación
            if (this.$refs.form) {
                this.$refs.form.resetValidation(); // Reinicia las validaciones si el formulario está montado
            }
        },
        async submitForm() {
            try {
                if (this.$refs.form.validate()) {
                    this.showLoader = true;
                    this.formData.type_of_action = this.type_of_action;
                    console.log("Formulario enviado:", this.formData);
                    let response = null;
                    let reason = '';

                    if (this.type_of_action === 'CHAT') {
                        //this.formData.reason = 'CHAT';
                        reason = 'CHAT';
                        //response = await addTodayActivity("CHAT", this.employeeData, this.formData);
                        console.log("response: ", response);
                    }

                    else if (this.type_of_action === 'EMAIL-SMS') {
                        //this.formData.reason = 'EMAIL-SMS';
                        reason = 'EMAIL-SMS';
                    } else if (this.type_of_action === 'WALK IN') {
                        reason = 'WALK IN';
                        //response = await addTodayActivity("Walk In", this.employeeData, this.formData);
                        //console.log("response: ", response);
                    } else {
                        //response = await addTodayActivity(this.formData.reason, this.employeeData, this.formData);r
                        reason = this.formData.reason;
                    }

                    if (Object.keys(this.employeeData).length === 0) {
                        console.log("<<<<<<<<<<<<<<<<<<<< El objeto está vacío.");
                        await this.getEmployeeData();
                    } else {
                        console.log("<<<<<<<<<<<<<<<<<<<< El objeto tiene propiedades.");
                    }

                    response = await addTodayActivity(reason, this.employeeData, this.formData);
                    console.log("response: ", response);

                    if (response.ok) {
                        this.formDialog = false;
                        this.activities = await fetchTodayActivities(this.employeeData);
                        let body = {
                            employeeData: this.employeeData,
                        };
                        this.callsSummary = await getCallsSummary(body);
                        console.log("this.callsSummary: ", this.callsSummary);
                    }
                } else {
                    console.log("Formulario no válido");
                }
                this.showLoader = false;
            } catch (error) {
                console.error("Error to add activity: ", error);
                this.showLoader = false;
                alert("Error to add activity: ", error);

            }
        },
        showConfirmDialog() {
            this.dialog = true; // Shows the dialog
        },
        async confirmClockOut() {
            console.log('<<<<<<<<<<< Clocking out'); // Message when clocking out
            const data = {
                "type_of_job": this.type_of_job
            }
            console.log("data: ", data);
            const response = await addTodayActivity("CLOCK OUT", this.employeeData, data);
            this.dialog = false; // Closes the dialog
            // Here you can handle the response if necessary
            if (response.ok) {
                this.clockIn = !this.clockIn; // Cambiar el estado
                this.activities = await fetchTodayActivities(this.employeeData);
                this.clock = this.activities[0].actions.clock;
                this.wc = this.activities[0].actions.wc;
                this.lunch = this.activities[0].actions.lunch;
                console.log("this.clock: ", this.clock);
                console.log("this.wc: ", this.wc);
                console.log("this.lunch: ", this.lunch);
                this.closedDay = this.activities[0].status;
                await fetchActivitySummary(this.employeeData, this.type_of_job);
            }
        },
        handleClick(label) {
            console.log(`Clicked on: ${label}`);
            this.type_of_action = label;
            if (this.type_of_action === 'WALK IN') {
                this.openWalkIn();
            } else {
                this.formDialog = true;
            }
        },
        async toggleAdminTask() {
            try {
                this.showLoader = true;
                let response = null;

                if (Object.keys(this.employeeData).length === 0) {
                    console.log("<<<<<<<<<<<<<<<<<<<< El objeto está vacío.");
                    await this.getEmployeeData();
                } else {
                    console.log("<<<<<<<<<<<<<<<<<<<< El objeto tiene propiedades.");
                }

                console.log("toggleAdminTask/this.activities: ", this.activities);
                if (!this.adminTask) {

                    if (this.formData.reason != '') {
                        if (this.activities.length > 0) {
                            console.log('<<<<<<<<<< ADMIN TASK in for n time'); // Mensaje al hacer clock in                    
                            response = await addTodayActivity("ADMIN TASK IN", this.employeeData, this.formData);
                            this.adminTask = true;
                        }
                    } else {
                        alert("You need to type a reason");
                        return;
                    }
                }
                else {
                    console.log('<<<<<<<<<<< ADMIN TASK out', this.activities); // Mensaje al hacer clock out
                    let array_activity = this.activities[0].activities;
                    let last_activity = null;
                    if (Array.isArray(array_activity) && array_activity.length > 0) {
                        last_activity = array_activity[array_activity.length - 1]; // Retorna el último objeto
                        console.log("last_activity: ", last_activity);
                    }
                    let reason = last_activity;

                    response = await addTodayActivity("ADMIN TASK OUT", this.employeeData, reason);
                }

                if (response.ok) {
                    this.adminTaskIn = !this.adminTaskIn; // Cambiar el estado
                    this.activities = await fetchTodayActivities(this.employeeData);
                    this.clock = this.activities[0].actions.clock;
                    this.wc = this.activities[0].actions.wc;
                    this.lunch = this.activities[0].actions.lunch;
                    this.adminTask = this.activities[0].actions.adminTask;
                    console.log("this.clock: ", this.clock);
                    console.log("this.wc: ", this.wc);
                    console.log("this.lunch: ", this.lunch);
                    console.log("this.adminTask: ", this.adminTask);
                    this.closedDay = this.activities[0].status;
                    this.formDialog = false;
                }
            } catch (error) {
                console.error("Error Toggle Admin Task!");
                alert("Error Toggle Admin Task!")
            }
            this.showLoader = false;
        },
        async toggleLunch() {
            try {
                this.showLoader = true;
                let response = null;

                if (Object.keys(this.employeeData).length === 0) {
                    console.log("<<<<<<<<<<<<<<<<<<<< El objeto está vacío.");
                    await this.getEmployeeData();
                } else {
                    console.log("<<<<<<<<<<<<<<<<<<<< El objeto tiene propiedades.");
                }

                console.log("this.activities: ", this.activities);
                if (!this.lunch) {
                    //if (this.clockIn) {
                    if (this.activities.length > 0) {
                        console.log('<<<<<<<<<< GO TO LUNCH in for n time'); // Mensaje al hacer clock in
                        response = await addTodayActivity("GO TO LUNCH", this.employeeData, {});
                        this.lunch = true;
                    }
                }
                else {
                    console.log('<<<<<<<<<<< BACK LUNCH out'); // Mensaje al hacer clock out
                    response = await addTodayActivity("BACK LUNCH", this.employeeData, {});
                }

                if (response.ok) {
                    this.lunchIn = !this.lunchIn; // Cambiar el estado
                    this.activities = await fetchTodayActivities(this.employeeData);
                    this.clock = this.activities[0].actions.clock;
                    this.wc = this.activities[0].actions.wc;
                    this.lunch = this.activities[0].actions.lunch;
                    this.adminTask = this.activities[0].actions.adminTask;
                    console.log("this.clock: ", this.clock);
                    console.log("this.wc: ", this.wc);
                    console.log("this.lunch: ", this.lunch);
                    console.log("this.adminTask: ", this.adminTask);
                    this.closedDay = this.activities[0].status;
                }
            } catch (error) {
                console.error("Error Toggle Lunch!")
                alert("Error Toggle Lunch!")
            }
            this.showLoader = false;
        },
        async toggleWC() {
            try {
                this.showLoader = true;
                let response = null;

                if (Object.keys(this.employeeData).length === 0) {
                    console.log("<<<<<<<<<<<<<<<<<<<< El objeto está vacío.");
                    await this.getEmployeeData();
                } else {
                    console.log("<<<<<<<<<<<<<<<<<<<< El objeto tiene propiedades.");
                }

                console.log("this.activities: ", this.activities);
                if (!this.wc) {
                    //if (this.clockIn) {
                    if (this.activities.length > 0) {
                        console.log('<<<<<<<<<< BIO BREAK for n time'); // Mensaje al hacer clock in
                        response = await addTodayActivity("BIO BREAK", this.employeeData, {});
                        this.wc = true;
                    }
                }
                else {
                    console.log('<<<<<<<<<<< BACK BIO BREAK'); // Mensaje al hacer clock out
                    response = await addTodayActivity("BACK BIO BREAK", this.employeeData, {});
                }

                if (response.ok) {
                    this.wcIn = !this.wcIn; // Cambiar el estado
                    this.activities = await fetchTodayActivities(this.employeeData);
                    this.clock = this.activities[0].actions.clock;
                    this.wc = this.activities[0].actions.wc;
                    this.lunch = this.activities[0].actions.lunch;
                    this.adminTask = this.activities[0].actions.adminTask;
                    console.log("this.adminTask: ", this.adminTask);
                    console.log("this.clock: ", this.clock);
                    console.log("this.wc: ", this.wc);
                    console.log("this.lunch: ", this.lunch);
                    console.log("this.adminTask: ", this.adminTask);
                }
            } catch (error) {
                console.error("Error Toggle WC!")
                alert("Error Toggle WC!");
            }
            this.showLoader = false;
        },
        async toggleClock() {
            try {
                this.showLoader = true;
                let response = null;
                console.log("this.activities: ", this.activities);

                /* if (Object.keys(this.employeeData).length === 0) {
                    console.log("<<<<<<<<<<<<<<<<<<<< El objeto está vacío.");
                    await this.getEmployeeData();
                } else {
                    console.log("<<<<<<<<<<<<<<<<<<<< El objeto tiene propiedades.");
                } */

                if (!this.clock) {/*  && !this.loading */
                    if (this.activities.length > 0) {
                        console.log('<<<<<<<<<< Clocking in for n time'); // Mensaje al hacer clock in
                        response = await addTodayActivity("CLOCK IN", this.employeeData, {});
                        this.clock = true;
                    } else {
                        console.log('<<<<<<<<<<< Clocking in for first time'); // Mensaje al hacer clock in
                        const data = {
                            "employee_data": this.employeeData,
                            "extension": this.extension
                        }
                        console.log("data: ", data);
                        response = await createActivity(data);
                        console.log("response: ", response);
                        this.clock = true;
                    }
                    if (response.ok) {
                        this.clockIn = !this.clockIn; // Cambiar el estado
                        this.activities = await fetchTodayActivities(this.employeeData);
                        this.clock = this.activities[0].actions.clock;
                        this.wc = this.activities[0].actions.wc;
                        this.lunch = this.activities[0].actions.lunch;
                        this.adminTask = this.activities[0].actions.adminTask;
                        console.log("this.clock: ", this.clock);
                        console.log("this.wc: ", this.wc);
                        console.log("this.lunch: ", this.lunch);
                        console.log("this.adminTask: ", this.adminTask);
                        this.closedDay = this.activities[0].status;
                    }
                }
                else {
                    console.log('<<<<<<<<<<< Clocking out'); // Mensaje al hacer clock out
                    this.showConfirmDialog();
                }
                this.showLoader = false;
            } catch (error) {
                console.error("Error Toggle Clock!");
                alert("Error Toggle Clock!")
            }
        },
        getRowClass(item) {
            if (item.reason.startsWith('CHAT')) {
                return 'row-chat'; // Clase para cualquier caso que comience con "Chat"
            } else if (item.reason.startsWith('Walk In')) {
                return 'row-walk-in';
            } else if (item.reason.startsWith('EMAIL-SMS')) {
                return 'row-email-sms';
            } else if (item.reason.startsWith('Info/Sold')) {
                return 'row-info-sold';
            } else if (item.type_of_call.type.startsWith('ADMIN TASK')) {
                return 'row-admin-task';
            } else if (item.type_of_call.type.startsWith('INBOUND')) {
                return 'row-inbound';
            } else if (item.type_of_call.type.startsWith('OUTBOUND')) {
                return 'row-outbound';
            } else if (item.reason.includes('LUNCH')) {
                return 'row-lunch';
            } else if (item.reason.includes('BIO')) {
                return 'row-bio';
            }

            switch (item.reason) {
                /* case 'Other':
                    return 'row-other';
                case 'Outbound MC':
                    return 'row-outbound-mc'; */
                /* case 'Info/Sold':
                    return 'row-info-sold'; */
                /* case 'Info/Not Sold':
                    return 'row-info-not-sold';
                case 'Complaint':
                    return 'row-complaint';
                case 'Schedule':
                    return 'row-schedule';
                case 'Outbound FU':
                    return 'row-outbound-fu';
                case 'CHAT':
                    return 'row-chat';
                case 'Personal FU':
                    return 'row-personal-fu'; */
                /* case 'Walk In':
                    return 'row-walk-in';
                case 'Walk In - Info/Sold other':
                    return 'row-walk-in';
                case 'Walk In - Info/Sold mine':
                    return 'row-walk-in'; */
                case 'CLOCK IN':
                    return 'row-clock-in';
                case 'CLOCK OUT':
                    return 'row-clock-out';
                default:
                    return '';
            }
        },
        redirectToLink(link) {
            if (!/^https?:\/\//i.test(link)) {
                link = `https://${link}`;
            }
            window.open(link, '_blank');
        },
        resetFilters() {
            this.period.end_date = null;
            this.period.start_date = null;
            this.employeeSelected = null;
            this.filters = {
                creationDate: null,
                staff_name: null,
                reason: null,
            };
        },
        async searchFilters() {
            this.showLoader = true;
            this.filters.staff_name = this.name_staff;
            if (this.period.start_date === null) {
                const chicagoTime = new Date().toLocaleDateString('en-US', { timeZone: 'America/Chicago' });
                //this.period.start_date = chicagoTime;
                const [month, day, year] = chicagoTime.split("/");
                this.period.start_date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
                this.period.end_date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            } else {
                this.period.end_date = this.period.start_date;
            }
            /* if (this.period.end_date === null && this.period.start_date !== null) {
                //this.period.end_date = this.period.start_date
                const today = new Date();
                const yyyy = today.getFullYear();
                const mm = String(today.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11, por eso sumamos 1
                const dd = String(today.getDate()).padStart(2, '0');
                this.period.end_date = `${yyyy}-${mm}-${dd}`;
            } */
            this.filters.creationDate = [this.period.start_date, this.period.end_date];
            this.filters.creationTime = [this.period.start_time, this.period.end_time];
            console.log("filters: ", this.filters);
            this.activities = await fetchActivitiesWithReasonEmployee(this.filters);
            console.log("this.activities", this.activities);
            this.showLoader = false;
        },
        openForm() {
            console.log("Opening Form Dialog");
            this.walkPreview = true;
        },
        formatDate(date) {
            const chicagoTime = DateTime.fromISO(date, { zone: 'utc' }).setZone('America/Chicago');
            // Format the date in 'yyyy-MM-dd HH:mm:ss' format
            return chicagoTime.toFormat('yyyy-MM-dd HH:mm:ss');
        },
        preview(item, index) {
            console.log("########## Preview......");
            console.log("item: ", item);
            console.log("index: ", index);
            this.walkPreview = true;
            this.walkIndex = index;
            this.walkItem = item;
            this.itemPreview = index;
        },
        async refreshActivities() {
            this.showLoader = true;
            this.activities = await fetchTodayActivities(this.employeeData);
            console.log("this.activities", this.activities);
            let body = {
                employeeData: this.employeeData,
            };
            this.callsSummary = await getCallsSummary(body);
            console.log("this.callsSummary: ", this.callsSummary);

            if (this.activities.length === 0) {
                this.clockIn = true;
                this.lunchIn = true;
                this.wcIn = true;
                this.clock = null;
                this.wc = null;
                this.lunch = null;
                this.adminTask = null;
                this.closedDay = false;
            } else {
                this.clock = this.activities[0].actions.clock;
                this.wc = this.activities[0].actions.wc;
                this.lunch = this.activities[0].actions.lunch;
                this.adminTask = this.activities[0].actions.adminTask;
                this.closedDay = this.activities[0].status;
                this.clockIn = !this.clock;
                this.lunchIn = !this.lunch;
                this.lunchIn = !this.wc;
            }
            this.showLoader = false;
        },
        async get_WalkIns_Forms() {
            try {
                this.showLoader = true;
                console.log("Getting training logs.....")
                console.log("employeeData: ", this.employeeData);
                const data = await fetchTodayWalkIns_ByEmployee(this.employeeData);
                console.log("data: ", data);

                if (data.length === 0) {
                    this.showLoader = false;
                    return [];
                    //throw new Error('Failed to fetch record');
                }

                //const res = await response.json();
                //const data = response;
                //console.log("<<<<<<<<<<<<<<<<<<< res data: ", data);
                this.showLoader = false;
                return data;
            } catch (error) {
                this.showLoader = false;
                console.error('Error fetching record:', error);
            }
        },
    },
}
</script>

<style>
.cursor-pointer {
    cursor: pointer;
    /* Cambia el cursor al pasar sobre la imagen */
}

.wrap-column {
    white-space: normal !important;
    word-wrap: break-word !important;
    overflow-wrap: anywhere !important;
    text-align: left;
}

/* Tonos pastel */
.row-other {
    background-color: #ffd1dc !important;
    /* Rosado pastel */
}

.row-outbound-mc {
    background-color: #fff9c4 !important;
    /* Amarillo pastel */
}

.row-info-sold {
    background-color: #daffb0 !important;
    /* Verde pastel */
}

.row-info-not-sold {
    background-color: #ffcdd2 !important;
    /* Rojo pastel */
}

.row-complaint {
    background-color: #b2ebf2 !important;
    /* Turquesa pastel */
}

.row-schedule {
    background-color: #ffe0b2 !important;
    /* Naranja pastel */
}

.row-outbound-fu {
    background-color: #bbdefb !important;
    /* Azul pastel */
}

.row-outbound {
    background-color: #bbdefb !important;
    /* Azul pastel */
}

.row-inbound {
    background-color: #daffb0 !important;
    /* Verde pastel */
}

.row-chat {
    background-color: #f8bbd0 !important;
    /* Rosado pastel más suave */
}

.row-personal-fu {
    background-color: #e1bee7 !important;
    /* Morado pastel */
}

.row-walk-in {
    background-color: #90caf9 !important;
    /* Azul marino pastel */
}

.row-admin-task {
    background-color: #f990f0 !important;
    /* Azul marino pastel */
}

.row-lunch {
    background-color: #2eff8c !important;
    /* Azul marino pastel */
}

.row-bio {
    background-color: #cc7834 !important;
    /* Azul marino pastel */
}

.row-email-sms {
    background-color: #90a0f9 !important;
    /* Azul marino pastel */
}

.row-clock-in {
    background-color: #a4ff9c !important;
    /* Azul marino pastel */
}

.row-clock-out {
    background-color: #ff9090 !important;
    /* Azul marino pastel */
}
</style>
