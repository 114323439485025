<template>
  <v-app>
    <!-- Login: Se muestra si el usuario no está autenticado -->
    <v-main v-if="!loggedIn">
      <router-view />
    </v-main>

    <!-- Interfaz principal: Se muestra si el usuario está autenticado -->
    <v-main v-else>
      <!-- Barra superior -->
      <v-app-bar color="black" dark height="90">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

        <!-- Logo -->
          <v-toolbar-title class="title-text">
            <router-link to="/home">
              <v-img src="./assets/Logos/logoBlanco.png" alt="Logo" max-height="60" contain></v-img>
            </router-link>
          </v-toolbar-title>
        </v-app-bar>

      <!-- Menú de navegación -->
      <v-navigation-drawer v-if="loggedIn" app v-model="drawer" absolute temporary>
        <v-list-item>
          <v-list-item-avatar>
            <!-- <v-icon large>mdi-account-circle</v-icon> -->
            <v-img src="./assets/Logos/logo32x32.png" alt="Logo" max-height="60" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">{{ name }} {{ last_name }}</v-list-item-title>
            <!-- <v-list-item-title>{{ last_name }}</v-list-item-title> -->
            <v-list-item-subtitle>{{ employee_number }}</v-list-item-subtitle>
            <v-list-item-subtitle class="text-wrap">{{ department }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list nav dense>
          <!-- HR -->
          <!-- v-if="permissions === 'Administrator' || permissions === 'Analyst'" -->
          <v-list-group v-if="hr" prepend-icon="mdi-account-group" active-class="deep-purple--text" no-action>
            <template v-slot:activator>
              <v-list-item-title class="menu-item-text">HR</v-list-item-title>
            </template>
            <v-list-item @click="navigateTo('/employees')">
              <v-list-item-title>Employees</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/policies')">
              <v-list-item-title>Policies</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <!-- IT -->
          <!-- v-if="permissions === 'Administrator' || permissions === 'Technical Support'" -->
          <v-list-group v-if="it" prepend-icon="mdi-laptop" active-class="deep-purple--text" no-action>
            <template v-slot:activator>
              <v-list-item-title class="menu-item-text">IT</v-list-item-title>
            </template>
            <v-list-item
              v-if="permissions === 'Technical Support' || permissions === 'Administrator' || permissions === 'Coordinator'"
              @click="navigateTo('/permissions')">
              <v-list-item-title>Permissions</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="permissions === 'Technical Support' || permissions === 'Administrator' || permissions === 'Coordinator'"
              @click="navigateTo('/computer-equipment')">
              <v-list-item-title>Computer Equipment</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="permissions === 'Technical Support' || permissions === 'Administrator' || permissions === 'Coordinator'"
              @click="navigateTo('/computer-equipment-assignation')">
              <v-list-item-title>Computer Equipment Assignation</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/zip-codes')">
              <v-list-item-title>Zip Codes</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/report-to')">
              <v-list-item-title>Report to</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/locations')">
              <v-list-item-title>Locations</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <!-- Fleet -->
          <v-list-group v-if="fleet" prepend-icon="mdi-car" active-class="deep-purple--text" no-action>
            <template v-slot:activator>
              <v-list-item-title class="menu-item-text">Fleet</v-list-item-title>
            </template>
            <v-list-item @click="navigateTo('/vehicles')">
              <v-list-item-title>Vehicles</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/instructors')">
              <v-list-item-title>Drivers</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="permissions === 'Administrator' || permissions === 'Technical Support' || permissions === 'Coordinator'"
              @click="navigateTo('/insurance')">
              <v-list-item-title>Insurance company</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/type-of-coverage')">
              <v-list-item-title>Type of Coverage</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <!-- Accounting -->
          <v-list-group v-if="accounting" prepend-icon="mdi-cash-multiple" active-class="deep-purple--text" no-action>
            <template v-slot:activator>
              <v-list-item-title class="menu-item-text">Accounting</v-list-item-title>
            </template>
            <v-list-item @click="navigateTo('/expense-record')">
              <v-list-item-title>Expense Record</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="accounting_validation" @click="navigateTo('/expense-record-admin-panel')">
              <v-list-item-title>Validate Expense Record</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/location-excel')">
              <v-list-item-title>Main Location Filter</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/credit-card')">
              <v-list-item-title>Payment Cards Manager</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/porcentage-interface')">
              <v-list-item-title>Porcentages</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/zoho-chart-of-account')">
              <v-list-item-title>Zoho Chart Of Account</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/zoho-vendors')">
              <v-list-item-title>Zoho Vendors (Suppliers)</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/zoho-currency')">
              <v-list-item-title>Zoho Currency</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/zoho-banks')">
              <v-list-item-title>Zoho Banks</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <!-- Training Logs -->
          <v-list-group v-if="training_logs" prepend-icon="mdi-file-document-outline" active-class="deep-purple--text"
            no-action>
            <template v-slot:activator>
              <v-list-item-title class="menu-item-text">Training Logs</v-list-item-title>
            </template>
            <v-list-item v-if="permissions === 'Administrator' || permissions === 'Coordinator'"
              @click="navigateTo('/new-admin-trainer-interface')">
              <v-list-item-title>New Admin console</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/new-trainer-interface')">
              <v-list-item-title>New Trainer Console</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="permissions === 'Administrator' || permissions === 'Coordinator'"
              @click="navigateTo('/admin-trainer-interface')">
              <v-list-item-title>Admin Console</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/trainer-interface')">
              <v-list-item-title>Trainer Console</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <!-- Walk ins -->
          <v-list-group v-if="walk_ins" prepend-icon="mdi-foot-print" active-class="deep-purple--text" no-action>
            <template v-slot:activator>
              <v-list-item-title class="menu-item-text">Walk-Ins</v-list-item-title>
            </template>
            <v-list-item @click="navigateTo('/user-walk-ins')">
              <v-list-item-title>User Console</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="permissions === 'Administrator' || permissions === 'Analyst' || permissions === 'Coordinator'"
              @click="navigateTo('/admin-walk-ins')">
              <v-list-item-title>Admin Console</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <!-- Marketing -->
          <v-list-group v-if="marketing" prepend-icon="mdi-bullhorn" active-class="deep-purple--text" no-action>
            <template v-slot:activator>
              <v-list-item-title class="menu-item-text">Marketing</v-list-item-title>
            </template>
            <v-list-item @click="navigateTo('/email-validator')">
              <v-list-item-title>Nova Email Validator</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/nova-analytics')">
              <v-list-item-title>Nova Analytics</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/lead-system')">
              <v-list-item-title>Nova Lead System</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <!-- Sales -->
          <v-list-group v-if="sales" prepend-icon="mdi-chart-line" active-class="deep-purple--text" no-action>
            <template v-slot:activator>
              <v-list-item-title class="menu-item-text">Sales</v-list-item-title>
            </template>

            <v-list-item
              v-if="permissions === 'Administrator' || permissions === 'Coordinator'"
              @click="navigateTo('/calls-goal')">
              <v-list-item-title>Calls Goal</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="permissions === 'Administrator' || permissions === 'Coordinator'"
              @click="navigateTo('/sales-goal')">
              <v-list-item-title>Sales Goal</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="permissions === 'Administrator' || permissions === 'Coordinator'"
              @click="navigateTo('/collected-goal')">
              <v-list-item-title>Collected Goal</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="permissions === 'Administrator' || permissions === 'Coordinator'"
              @click="navigateTo('/monthly-sales-goal')">
              <v-list-item-title>Monthly Sales Goal</v-list-item-title>
            </v-list-item>
          </v-list-group>


          <v-list-group v-if="activity_report" prepend-icon="mdi-clipboard-list" active-class="deep-purple--text"
            no-action>
            <template v-slot:activator>
              <v-list-item-title class="menu-item-text">Activity Report</v-list-item-title>
            </template>
            <v-list-item @click="navigateTo('/user-activity-report')">
              <v-list-item-title>Activity Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="permissions === 'Administrator' || permissions === 'Coordinator'"
              @click="navigateTo('/admin-activity-report')">
              <v-list-item-title>Admin Activity Report</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <!-- Account -->
          <v-list-group prepend-icon="mdi-account" active-class="deep-purple--text" no-action>
            <template v-slot:activator>
              <v-list-item-title class="menu-item-text">My Account</v-list-item-title>
            </template>
            <v-list-item @click="navigateTo('/employeesform')">
              <v-list-item-title>MY PROFILE</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/payroll')">
              <v-list-item-title>Payroll</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <!-- Botón Log Out -->
          <v-list-item class="logout-item" @click="logout">
            <v-list-item-icon>
              <v-icon color="red">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <!-- Contenedor para renderizar vistas -->
      <!-- <v-container>
        <router-view />
      </v-container> -->
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { bus } from './main.js';
import { get_permissions } from './utils/permission.js';

export default {
  name: 'App',
  data: () => ({
    imageHeight: null,
    loggedIn: false,
    drawer: false,
    group: null,
    permissions: '',
    walk_ins: null,
    training_logs: null,
    name: '',
    last_name: '',
    employee_number: '',
    department: '',
    accounting: '',
    accounting_validation: null,
    marketing: null,
    fleet: null,
    activity_report: null,
    sales: null,
  }),
  mounted() {
    /* const imageName = require('@/assets/Logos/LogoBlanco.png'); // Cambia el path según tu estructura
    this.getImageHeight(imageName); */
  },
  created() {
    const token = localStorage.getItem('token');

    if (token) {
      this.loggedIn = true;
      bus.$emit('login-success');
      this.loadPermissions();
    }

    bus.$on('login-success', () => {
      this.loggedIn = true;
      this.loadPermissions();
    });

    bus.$on('logout-event', () => {
      this.loggedIn = false;
      localStorage.removeItem('token');
    });
  },
  methods: {
    /* getImageHeight(imageSrc) {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        this.imageHeight = img.height;
        console.log(`Altura de la imagen (${imageSrc}):`, img.height);
      };
      img.onerror = () => {
        console.error(`No se pudo cargar la imagen: ${imageSrc}`);
      };
    }, */
    logout() {
      localStorage.removeItem('token');
      this.loggedIn = false;
      this.$router.push({ path: '/' });
    },
    loadPermissions() {
      const { permissions, walk_ins, training_logs, name, last_name, employee_number, department, accounting, accounting_validation, marketing, fleet, hr, it, activity_report, sales } = get_permissions();
      this.permissions = permissions;
      console.log("this.permissions: ", this.permissions);
      this.walk_ins = walk_ins;
      this.training_logs = training_logs;
      this.name = name;
      this.last_name = last_name;
      this.employee_number = employee_number;
      this.department = department;
      this.accounting = accounting;
      this.accounting_validation = accounting_validation;
      this.marketing = marketing;
      this.fleet = fleet;
      this.hr = hr;
      this.it = it;
      this.activity_report = activity_report;
      this.sales = sales;
    },
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
/* Estilo para el título */
.title-text {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

/* Botón de logout */
.logout-item {
  margin-top: auto;
  color: red;
}

/* Contenedor para las vistas */
v-container {
  margin-top: 64px;
  /* padding: 16px; */
}

/* Cambiar el tamaño del texto principal (grupos) */
.v-list-group__header .menu-item-text {
  font-size: 16px !important;
  /* Aumenta el tamaño */
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #000000;
}

/* Cambiar el tamaño del texto de los subítems */
.v-list-item .menu-subitem-text {
  font-size: 20px !important;
  /* Aumenta el tamaño */
  font-family: "Arial", sans-serif;
  font-weight: 600;
  color: #e0e0e0;
}

/* Cambiar el tamaño de los íconos */
.v-list-item-icon v-icon {
  font-size: 32px !important;
  /* Cambia el tamaño del ícono */
  color: #fff !important;
  /* Asegura que el color sea visible */
}
</style>
